<template>
  <v-dialog v-model="defaultFilter" max-width="600">
    <v-card>
      <v-toolbar color="primary" dark elevation="0">
        <v-icon @click="defaultFilter = false">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center">
          Standardfilter bearbeiten
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-select
          label="Terminlokationen"
          multiple
          chips
          deletable-chips
          v-model="activeLocations"
          :items="locations"
          :item-value="item => item"
          item-text="location.name"
        ></v-select>
        <v-select
          label="Terminbereiche"
          multiple
          chips
          deletable-chips
          v-model="activeScopes"
          :items="scopes"
          :item-value="item => item"
          item-text="scope.name"
        ></v-select
      ></v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()">Speichern</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'

export default {
  data: () => ({
    defaultFilter: false,
    scopes: '',
    locations: '',
    activeScopes: '',
    activeLocations: '',
    userId: ''
  }),
  methods: {
    open(userId) {
      this.defaultFilter = true
      this.userId = userId
      this.loadDefaultFilter()
    },
    async loadDefaultFilter() {
      axios
        .get(`/v1/calv2/information/defaultFilter/${this.userId}`)
        .then(data => {
          this.scopes = data.data.scopes
          this.activeScopes = data.data.scopes.filter(scope => scope.isActive)
          this.locations = data.data.locations
          this.activeLocations = data.data.locations.filter(
            location => location.isActive
          )
        })
    },
    save() {
      var obj = {
        scopes: this.activeScopes,
        locations: this.activeLocations
      }
      axios
        .post(`/v1/calv2/information/defaultFilter/${this.userId}`, obj)
        .then(() => {
          this.defaultFilter = false
          this.$toast.success('Standardfilter wurde gespeichert!')
        })
    }
  },
  mounted() {}
}
</script>

<style></style>
