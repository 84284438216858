<template
  ><v-data-table
    :headers="headers"
    :items="allUsers"
    :items-per-page="3"
    :search="search"
    hide-default-footer
    ><template v-slot:top>
      <v-toolbar flat color="white">
        <v-text-field
          class="mt-8"
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          outlined
          dense
        ></v-text-field>
      </v-toolbar> </template
    ><template v-slot:no-data> Keine Daten gefunden. </template
    ><template v-slot:item.actions="{ item }">
      <v-btn
        fab
        small
        text
        elevation="0"
        class="mr-2 col-xl-5"
        @click="addUser(item)"
      >
        <v-icon small> mdi-plus </v-icon>
      </v-btn>
    </template></v-data-table
  ></template
>

<script>
import { mapState } from 'vuex'
export default {
  data: () => ({
    headers: [
      { text: 'PID', value: 'pid' },
      { text: 'Name', value: 'username' },
      { text: 'Kürzel', value: 'userId' },
      { text: 'E-Mail', value: 'email' },
      {
        text: 'Hinzufügen',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '12rem'
      }
    ],
    search: ''
  }),
  computed: {
    ...mapState({
      allUsers: state => state.userSettings.allUsers
    })
  },
  mounted() {
    this.$store.dispatch('loadAllUsers')
  },
  methods: {
    addUser(user) {
      this.allUsers.splice(this.allUsers.indexOf(user), 1)
      this.$emit('addUser', user)
    }
  }
}
</script>

<style></style>
