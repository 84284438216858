var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.table),expression:"table"}],attrs:{"headers":_vm.headers,"items":_vm.items,"dense":"","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{staticClass:"mt-8",attrs:{"append-icon":"mdi-magnify","label":"Suche","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-1 ml-5",attrs:{"elevation":"0","color":"primary"},on:{"click":_vm.addItem}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus ")]),_vm._v("Neuer Datensatz")],1)],1)]},proxy:true},_vm._l((_vm.headers),function(header,index){return {key:`item.${header.value}`,fn:function(props){return [(_vm.isBoolean(props.item, header))?[(props.item.key.lfdnr === _vm.editedItem.key.lfdnr)?_c('v-simple-checkbox',{key:index,model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):_c('v-simple-checkbox',{key:index,attrs:{"disabled":""},model:{value:(props.item[header.value]),callback:function ($$v) {_vm.$set(props.item, header.value, $$v)},expression:"props.item[header.value]"}})]:(_vm.isColor(_vm.getNestedPropertyValue(props.item, header.value)))?[(
            props.item.key.lfdnr === _vm.editedItem.key.lfdnr &&
              _vm.isColor(_vm.getNestedPropertyValue(props.item, header.value))
          )?[_c('v-text-field',{key:index,staticClass:"ma-0 pa-0",attrs:{"dense":"","single-line":"","hide-details":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({style:(_vm.swatchStyle)},on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}})]:_c('v-card',{key:index,staticClass:"rounded-pill fill-height elevation-0",style:({
            'background-color': _vm.getNestedPropertyValue(
              props.item,
              header.value
            )
          }),attrs:{"align":"center","justify":"center","width":"35","height":"35"}})]:[(
            props.item.key.lfdnr === _vm.editedItem.key.lfdnr &&
              !_vm.isANumber(_vm.getNestedPropertyValue(props.item, header.value)) &&
              props.item[header.value] !== undefined
          )?_c('v-text-field',{key:index,attrs:{"dense":"","single-line":"","hide-details":""},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):(
            props.item.key.lfdnr === _vm.editedItem.key.lfdnr &&
              _vm.isANumber(_vm.getNestedPropertyValue(props.item, header.value)) &&
              props.item[header.value] !== undefined
          )?_c('v-text-field',{key:index,attrs:{"dense":"","single-line":"","hide-details":"","type":"number","disabled":_vm.actionMode != 'add'},model:{value:(_vm.editedItem[header.value]),callback:function ($$v) {_vm.$set(_vm.editedItem, header.value, $$v)},expression:"editedItem[header.value]"}}):[_vm._v(_vm._s(_vm.getNestedPropertyValue(props.item, header.value)))]]]}}}),{key:"item.actions",fn:function({ item }){return [(item.key.lfdnr !== _vm.editedItem.key.lfdnr)?[_c('v-btn',{staticClass:"mr-2 col-xl-5",attrs:{"fab":"","small":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.editExistingItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"col-xl-5",attrs:{"fab":"","small":"","text":"","elevation":"0","color":"red"},on:{"click":function($event){return _vm.checkDeleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]:[_c('v-btn',{staticClass:"mr-2 col-xl-5",attrs:{"fab":"","small":"","text":"","elevation":"0","color":"red"},on:{"click":_vm.unAssignEditedItem}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close ")])],1),(!_vm.isNew)?_c('v-btn',{staticClass:"col-xl-5",attrs:{"fab":"","small":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.checkSaveItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-save-all ")])],1):_vm._e(),(_vm.isNew)?_c('v-btn',{staticClass:"col-xl-5",attrs:{"fab":"","small":"","text":"","elevation":"0"},on:{"click":function($event){return _vm.saveNewItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()]]}},{key:"no-data",fn:function(){return [_vm._v(" Keine Daten vorhanden ")]},proxy:true}],null,true)}),_c('WarningDialog',{ref:"warningSave",on:{"warningActionPerformed":_vm.warningActionPerformedSave}}),_c('WarningDialog',{ref:"warningDelete",on:{"warningActionPerformed":_vm.warningActionPerformedDelete}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }