<template>
  <v-tab-item
    key="user.userId"
    transition="no-transition"
    reverse-transition="no-transition"
  >
    <CalUserSettings
      v-if="app === 'cal'"
      :userId="user.userId"
      :userLvl="user.userLvl"
      :settings="settings"
    ></CalUserSettings>
    <CrmUserSettings v-if="app === 'crm' && false == true"></CrmUserSettings>
    <!--Veröubergehend deaktiviert-->
  </v-tab-item>
</template>

<script>
import { mapState } from 'vuex'
import CalUserSettings from './CalUserSettings.vue'

export default {
  computed: {
    ...mapState({
      masterData: state => state.masterData.masterData
    })
  },
  components: {
    CalUserSettings
  },
  data: () => ({
    userSettingsPanel: true
  }),
  props: {
    user: {
      type: Object,
      required: true
    },
    app: {
      type: String,
      required: true
    },
    settings: {
      type: Array,
      required: true
    }
  },
  methods: {}
}
</script>
