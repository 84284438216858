<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-container class="pa-0 d-flex justify-start align-center">
          <h4>
            {{ displayText
            }}<v-tooltip right open-on-click>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon small>
                    mdi-information
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ detailText }}</span>
            </v-tooltip>
          </h4>
        </v-container>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-container class="pa-0 d-flex justify-start">
          <v-select
            v-if="typeOfData === 'v-select'"
            :items="items"
            :label="displayText"
            v-model="valueLocal"
          ></v-select>
          <v-checkbox
            v-else-if="typeOfData === 'v-checkbox'"
            v-model="valueLocal"
            class="ma-0 pa-0"
          ></v-checkbox>
          <v-btn
            v-else-if="typeOfData === 'contextmenu'"
            outlined
            class="mb-4 mb-sm-0"
            small
            @click="openContextMenuDragAndDrop"
            >Reihenfolge bearbeiten</v-btn
          >
          <v-text-field
            v-else-if="typeOfData === 'number'"
            v-model="valueLocal"
            type="number"
          ></v-text-field>
          <v-btn
            v-else-if="typeOfData === 'defaultFilter'"
            outlined
            class="mb-4"
            small
            @click="openDefaultFilter"
            >Bearbeiten</v-btn
          >
        </v-container>
      </v-col>
    </v-row>

    <ContextMenuDragAndDrop
      ref="contextMenu"
      @saveContextMenu="saveContextMenu"
    />
    <DefaultFilter ref="defaultFilter" />
  </div>
</template>

<script>
import ContextMenuDragAndDrop from './ContextMenuDragAndDrop.vue'
import DefaultFilter from './DefaultFilter.vue'

export default {
  data: () => ({}),
  components: {
    ContextMenuDragAndDrop,
    DefaultFilter
  },
  methods: {
    openContextMenuDragAndDrop() {
      this.$refs.contextMenu.open(this.valueLocal.split(';'), this.items)
    },
    openDefaultFilter() {
      this.$refs.defaultFilter.open(this.userId)
    },
    saveContextMenu() {
      this.valueLocal = this.$refs.contextMenu.selected
    },
    getActiveScopes() {
      return this.items.scopes
    },
    getScopes() {
      return this.items
    }
  },
  mounted() {
    if (this.typeOfData === 'defaultFilter') {
      this.loadDefaultFilter()
    }
  },
  computed: {
    valueLocal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        this.$emit('changedValue', value)
      }
    }
  },
  props: {
    displayText: {
      type: String,
      required: true
    },
    detailText: {
      type: String,
      required: true
    },
    typeOfData: {
      type: String,
      required: true
    },
    value: {
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    userId: {
      type: String,
      required: false
    }
  }
}
</script>

<style></style>
