<template>
  <div class="mt-4">
    <v-card-text>
      <v-tabs grow v-model="tab"
        ><v-tab key="masterData">Stammdaten</v-tab>
        <v-tab key="userSettings" v-if="app === 'cal'"
          >Benutzereinstellungen</v-tab
        ></v-tabs
      >
      <!--Vorübergehend deaktiviert-->
      <v-tabs-items v-model="tab">
        <v-tab-item key="masterData" class="mt-6 mx-4 mb-4">
          <div class="component-container">
            <div v-for="(category, i) in masterDataApp.masterDataTabs" :key="i">
              <h2>
                {{ i }}
              </h2>
              <br />
              <v-expansion-panels focusable tile>
                <SettingsPanel
                  v-for="type in category"
                  :key="type.masterData"
                  :text="type.displayName"
                  :icon="type.icon"
                  :type="type.masterDataCategory"
                ></SettingsPanel>
                <v-divider class="m-0"></v-divider>
              </v-expansion-panels>
              <br />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="userSettings" class="mt-6 mx-4 mb-4"
          ><div class="component-container">
            <UserTable
              @addUser="addUser"
              ref="userTable"
              v-if="user.userLvl > 250"
            ></UserTable>
            <v-tabs v-model="userTab" grow>
              <v-tab key="user.userid">{{ user.username }}</v-tab>
              <v-tab v-for="user in users" :key="user.userid"
                >{{ user.username }}
                <v-btn icon @click="removeUser(user)" class="ml-2"
                  ><v-icon x-small>mdi-close</v-icon></v-btn
                ></v-tab
              ></v-tabs
            >
            <v-tabs-items v-model="userTab">
              <UserSettingsPanel
                :user="user"
                :app="app"
                :settings="settingsCurrentUser"
              ></UserSettingsPanel>
              <UserSettingsPanel
                v-for="user in users"
                :user="user"
                :app="app"
                :key="user.userid"
                :settings="user.settings"
              ></UserSettingsPanel
            ></v-tabs-items></div
        ></v-tab-item>
      </v-tabs-items>
      <Loading ref="loading" />
    </v-card-text></div
></template>

<script>
import SettingsPanel from '@/components/settings/SettingsPanel.vue'
import UserSettingsPanel from '@/components/settings/UserSettingsPanel.vue'
import UserTable from '@/components/settings/UserTable.vue'
import { mapState } from 'vuex'
import Loading from '@/components/calendar_v2/Loading.vue'

export default {
  computed: {
    ...mapState({
      masterDataApp: state => state.masterData.masterDataApp,
      user: state => state.user.user,
      settingsCal: state => state.userSettings.settings_cal,
      settingsCurrentUser: state => state.user.settings_cal
    })
  },
  data: () => ({
    tab: null,
    userTab: null,
    table: false,
    headers: [],
    items: [],
    text: '',
    users: []
  }),
  components: {
    SettingsPanel,
    UserSettingsPanel,
    UserTable,
    Loading
  },
  props: {
    app: {
      type: String
    },
    displayName: {
      type: String
    }
  },
  methods: {
    openTable(text) {
      this.$refs.settingsData.open(text)
    },
    addUser(user) {
      var app = this.app
      this.$refs.loading.open(`Einstellungen werden geladen ...`)
      this.$store.dispatch('loadSettings', { user, app }).then(() => {
        this.$refs.loading.close()
        user.settings = this.settingsCal
        this.users.push(user)
      })
    },
    removeUser(user) {
      this.users.splice(this.users.indexOf(user), 1)
      this.$refs.userTable.allUsers.push(user)
    }
  },
  mounted() {
    this.$store.dispatch('loadMasterDataApp', this.app)
    this.$store.dispatch('loadAllUsers')
  },
  watch: {
    app: function() {
      this.$store.dispatch('loadMasterDataApp', this.app)
      this.$refs.userTable.search = ''
      this.users = []
    }
  }
}
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>
