<template>
  <v-expansion-panel v-model="settingsPanel" @click="action">
    <v-expansion-panel-header class="justify-self-start" disable-icon-rotate>
      <div>
        <v-icon>{{ icon }}</v-icon>
        <span class="ml-2">{{ text }}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <SettingsData ref="settingsData" :category="type" @reload="action()" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex'
import SettingsData from '@/components/settings/SettingsData.vue'

export default {
  computed: {
    ...mapState({
      masterData: state => state.masterData.masterData
    })
  },
  data: () => ({
    settingsPanel: true,
    headers: [],
    items: []
  }),
  components: {
    SettingsData
  },
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  methods: {
    action() {
      this.$store.dispatch('loadMasterData', this.type).then(() => {
        this.$refs.settingsData.open(
          this.type,
          this.masterData.headers,
          this.masterData.items,
          this.masterData.clazz
        )
      })
    }
  },
  watch: {
    settingsPanel: function() {
      console.warn(this.settingsPanel)
    }
  }
}
</script>
