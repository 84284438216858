<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      dense
      v-show="table"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            class="mt-8"
            v-model="search"
            append-icon="mdi-magnify"
            label="Suche"
            outlined
            dense
          ></v-text-field>

          <v-btn
            elevation="0"
            color="primary"
            class="mt-1 ml-5"
            @click="addItem"
            ><v-icon class="mr-2">mdi-plus </v-icon>Neuer Datensatz</v-btn
          >
        </v-toolbar>
      </template>
      <template
        v-for="(header, index) in headers"
        v-slot:[`item.${header.value}`]="props"
      >
        <template v-if="isBoolean(props.item, header)">
          <v-simple-checkbox
            v-if="props.item.key.lfdnr === editedItem.key.lfdnr"
            v-model="editedItem[header.value]"
            :key="index"
          ></v-simple-checkbox>
          <v-simple-checkbox
            v-else
            v-model="props.item[header.value]"
            :key="index"
            disabled
          ></v-simple-checkbox>
        </template>
        <template
          v-else-if="isColor(getNestedPropertyValue(props.item, header.value))"
        >
          <template
            v-if="
              props.item.key.lfdnr === editedItem.key.lfdnr &&
                isColor(getNestedPropertyValue(props.item, header.value))
            "
          >
            <v-text-field
              v-model="editedItem[header.value]"
              dense
              single-line
              hide-details
              class="ma-0 pa-0"
              :key="index"
            >
              <template v-slot:append>
                <v-menu
                  v-model="menu"
                  top
                  nudge-bottom="105"
                  nudge-left="16"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <div :style="swatchStyle" v-on="on" />
                  </template>
                  <v-card>
                    <v-card-text class="pa-0">
                      <v-color-picker v-model="editedItem[header.value]" flat />
                    </v-card-text>
                  </v-card>
                </v-menu>
              </template>
            </v-text-field>
          </template>
          <v-card
            v-else
            class="rounded-pill fill-height elevation-0"
            :style="{
              'background-color': getNestedPropertyValue(
                props.item,
                header.value
              )
            }"
            align="center"
            justify="center"
            width="35"
            height="35"
            :key="index"
          >
          </v-card>
        </template>
        <template v-else>
          <v-text-field
            v-if="
              props.item.key.lfdnr === editedItem.key.lfdnr &&
                !isANumber(getNestedPropertyValue(props.item, header.value)) &&
                props.item[header.value] !== undefined
            "
            v-model="editedItem[header.value]"
            :key="index"
            dense
            single-line
            hide-details
          >
          </v-text-field>
          <v-text-field
            v-else-if="
              props.item.key.lfdnr === editedItem.key.lfdnr &&
                isANumber(getNestedPropertyValue(props.item, header.value)) &&
                props.item[header.value] !== undefined
            "
            v-model="editedItem[header.value]"
            :key="index"
            dense
            single-line
            hide-details
            type="number"
            :disabled="actionMode != 'add'"
          >
          </v-text-field>
          <template v-else>{{
            getNestedPropertyValue(props.item, header.value)
          }}</template>
        </template>
      </template>
      <template v-slot:item.actions="{ item }">
        <template v-if="item.key.lfdnr !== editedItem.key.lfdnr">
          <v-btn
            fab
            small
            text
            elevation="0"
            class="mr-2 col-xl-5"
            @click="editExistingItem(item)"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            fab
            small
            text
            elevation="0"
            color="red"
            class="col-xl-5"
            @click="checkDeleteItem(item)"
          >
            <v-icon small> mdi-delete </v-icon>
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            fab
            small
            text
            elevation="0"
            class="mr-2 col-xl-5"
            @click="unAssignEditedItem"
            color="red"
          >
            <v-icon small> mdi-close </v-icon>
          </v-btn>
          <v-btn
            v-if="!isNew"
            fab
            small
            text
            elevation="0"
            class="col-xl-5"
            @click="checkSaveItem(item)"
            ><v-icon small> mdi-content-save-all </v-icon></v-btn
          >
          <v-btn
            v-if="isNew"
            fab
            small
            text
            elevation="0"
            class="col-xl-5"
            @click="saveNewItem(item)"
          >
            <v-icon small> mdi-plus </v-icon>
          </v-btn>
        </template>
      </template>
      <template v-slot:no-data>
        Keine Daten vorhanden
      </template>
    </v-data-table>
    <WarningDialog
      ref="warningSave"
      @warningActionPerformed="warningActionPerformedSave"
    />
    <WarningDialog
      ref="warningDelete"
      @warningActionPerformed="warningActionPerformedDelete"
    />
  </div>
</template>

<script>
import WarningDialog from '@/components/core/WarningDialog.vue'
import masterDataService from '@/services/settings/masterDataService.js'

export default {
  data: () => ({
    table: false,
    type: '',
    headers: [],
    items: [],
    editedItem: {},
    menu: false,
    search: '',
    isNew: false,
    actionMode: 'undefined'
  }),
  props: {
    category: {
      type: String,
      required: true
    }
  },
  components: {
    WarningDialog
  },
  computed: {
    color: {
      get() {
        return this.editedItem.color
      },
      set(newColor) {
        return newColor
      }
    },
    swatchStyle() {
      const { menu, color } = this
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  methods: {
    saveNewItem() {
      var i = this.items.findIndex(
        item => (item.key.lfdnr = this.editedItem.key.lfdnr)
      )

      this.items.splice(i, 1)
      this.items.unshift(this.editedItem)
      this.initializeDefaultValues()

      masterDataService
        .saveMasterData(this.category, this.editedItem)
        .then(() => {
          this.$toast.success('Datensatz wurde erstellt!')
          this.$emit('reload')
        })

      this.isNew = false
    },
    initializeDefaultValues() {
      var newLfdnr = Math.max(...this.items.map(i => i.key.lfdnr)) + 1
      this.editedItem = Object.assign({}, this.items[0], {
        key: { lfdnr: newLfdnr }
      })
    },
    isObjectKey() {
      var keyValue = this.getKeyValue()
      if (keyValue == 'id' || keyValue == 'lfdnr') {
        return false
      }
      return true
    },
    getKeyValue() {
      this.headers.forEach(header => {
        if (
          header.value == 'lfdnr' ||
          header.value == 'key' ||
          header.value == 'id'
        ) {
          return header.value
        }
      })
    },
    getIncrementedId() {
      let highest = 1
      this.items.forEach(item => {
        if (item.lfdnr > highest) {
          highest = item.lfdnr
        }
      })
      return highest + 1
    },
    focusOnSecondClass(className) {
      const cells = this.$el.querySelectorAll(`.${className}`)
      if (cells.length >= 2) {
        const secondCell = cells[1] // Accessing the second occurrence
        const input = secondCell.querySelector('input')
        if (input) {
          input.focus()
        }
      }
    },
    addItem() {
      this.actionMode = 'add'
      this.unAssignEditedItem()
      var newItem = Object.assign({}, this.editedItem)
      newItem.lfdnr = this.getIncrementedId()
      this.items.unshift(newItem)
      this.editItem(newItem)
      this.isNew = true
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.color = item.color
      setTimeout(() => {
        this.focusOnSecondClass('v-text-field__slot')
      }, 1)
    },
    editExistingItem(item) {
      this.actionMode = 'edit'
      console.log(item)
      this.editItem(item)
    },
    unAssignEditedItem() {
      if (this.isNew) {
        var i = this.items.findIndex(
          item => item.key.lfdnr === this.editedItem.key.lfdnr
        )
        this.items.splice(i, 1)
        this.isNew = false
      }
      this.initializeDefaultValues()
    },
    checkSaveItem() {
      var actions = [
        {
          icon: 'mdi-content-save-all',
          text: 'Speichern',
          action: 'save'
        },
        { icon: 'mdi-close', text: 'Abbrechen', action: 'cancel' }
      ]
      this.$refs.warningSave.open(
        'Wollen Sie den Datensatz bearbeiten?',
        actions,
        this.editedItem
      )
    },
    checkDeleteItem(item) {
      var actions = [
        {
          icon: 'mdi-delete',
          text: 'Löschen',
          action: 'delete'
        },
        { icon: 'mdi-close', text: 'Abbrechen', action: 'cancel' }
      ]
      this.$refs.warningDelete.open(
        'Wollen Sie den Datensatz löschen?',
        actions,
        item
      )
    },
    warningActionPerformedSave(action) {
      if (action === 'save') {
        this.saveEditedItem(this.$refs.warningSave.additionalData)
      }
      this.unAssignEditedItem()
    },
    warningActionPerformedDelete(action) {
      if (action === 'delete')
        this.deleteItem(this.$refs.warningDelete.additionalData)
    },
    deleteItem(item) {
      masterDataService.deleteMasterData(this.category, item).then(() => {
        this.$toast.success('Datensatz wurde gelöscht!')
        this.$emit('reload')
      })
    },
    saveEditedItem() {
      /*var i = this.items.findIndex(
        item => (item.key.lfdnr = this.editedItem.key.lfdnr)
      )*/
      masterDataService
        .saveMasterData(this.category, this.editedItem)
        .then(() => {
          this.$emit('reload')
          this.$toast.success('Datensatz wurde bearbeitet!')
        })
    },
    open(type, headers, items, clazz) {
      this.clazz = clazz
      this.type = type
      this.headers = headers
      this.headers.forEach(element => {
        element.align = 'auto'
      })
      var actions = { text: 'Aktionen', value: 'actions', align: 'end' }
      this.headers.push(actions)
      this.items = items
      this.initializeDefaultValues()
      this.table = true
    },
    close() {
      this.table = false
    },
    getNestedPropertyValue(object, path) {
      const pathSegments = path.split('.')
      let value = object
      for (const segment of pathSegments) {
        if (value && segment in value) {
          value = value[segment]
        } else {
          value = null
          break
        }
      }
      return value
    },
    isBoolean(object, header) {
      if (
        typeof this.getNestedPropertyValue(object, header.value) === 'boolean'
      ) {
        header.align = 'center'
        return true
      }
      return false
    },
    isColor(property) {
      var reg = /^#([0-9a-f]{3}){1,2}$/i
      return reg.test(property)
    },
    isANumber(property) {
      return typeof property === 'number'
    }
  }
}
</script>

<style></style>
