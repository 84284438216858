<template>
  <v-dialog v-model="contextMenuDragAndDrop" max-width="600">
    <v-card>
      <v-toolbar color="primary" dark elevation="0">
        <v-icon @click="contextMenuDragAndDrop = false">mdi-close</v-icon>
        <v-toolbar-title class="flex text-center">
          Kontextmenü bearbeiten
        </v-toolbar-title>
      </v-toolbar>
      <v-simple-table>
        <tbody>
          <tr>
            <th style="text-align: center">Anzeigereihenfolge</th>
            <th style="text-align: center">Mögliche Elemente</th>
          </tr>
          <tr>
            <td>
              <draggable
                :list="selected"
                class="list-group"
                group="contextMenu"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
                @change="log"
              >
                <transition-group>
                  <v-row
                    class="ma-2 pa-2 rounded-1 draggableItem"
                    v-for="element in selected"
                    :key="element.id"
                    style="border: 1px solid #cfcfcf; border-radius: 5px"
                  >
                    <h4 class="font-weight-regular">
                      {{ element.name }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-icon small>mdi-drag-horizontal-variant</v-icon>
                  </v-row>
                </transition-group>
              </draggable>
            </td>
            <td>
              <draggable
                :list="diff"
                class="list-group"
                group="contextMenu"
                ghost-class="ghost"
                @start="dragging = true"
                @end="dragging = false"
              >
                <transition-group>
                  <v-row
                    class="ma-2 pa-2 rounded-1 draggableItem"
                    v-for="element in diff"
                    :key="element.id"
                    style="border: 1px solid #cfcfcf; border-radius: 5px"
                  >
                    <h4 class="font-weight-regular" :key="element.id">
                      {{ element.name }}
                    </h4>
                    <v-spacer></v-spacer>
                    <v-icon small :key="element.id"
                      >mdi-drag-horizontal-variant</v-icon
                    >
                  </v-row>
                </transition-group>
              </draggable>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save()">Speichern</v-btn>
      </v-card-actions>
    </v-card>
    <ErrorDialog
      :failMessage="'Es muss mindestens 1 Element ausgewählt sein!'"
      :opened="errorDialog"
      @closeDialog="errorDialog = false"
    />
  </v-dialog>
</template>

<script src="//cdnjs.cloudflare.com/ajax/libs/Vue.Draggable/2.20.0/vuedraggable.umd.min.js"></script>
<script>
import draggable from 'vuedraggable'
import contextMenuService from '@/services/settings/contextMenuService.js'
import ErrorDialog from '../core/ErrorDialog.vue'

export default {
  components: {
    draggable,
    ErrorDialog
  },
  data: () => ({
    contextMenuDragAndDrop: false,
    selected: [],
    toSelect: [],
    diff: [],
    dragging: false,
    errorDialog: false
  }),
  methods: {
    open(selected, toSelect) {
      selected.forEach(element => {
        this.selected.push(contextMenuService.toGermanName(element))
      })
      this.toSelect = toSelect

      const mappedSelected = this.selected.map(name => {
        const { id } = this.toSelect.find(item => item.name === name) || {
          id: null
        }
        return { name, id }
      })

      this.selected = mappedSelected
      this.diff = this.toSelect.filter(
        item => !this.selected.some(otherItem => otherItem.name === item.name)
      )
      this.contextMenuDragAndDrop = true
    },
    save() {
      this.$emit('saveContextMenu')
      this.contextMenuDragAndDrop = false
    },
    log(event) {
      if (event.removed !== undefined && this.selected.length === 1) {
        this.errorDialog = true
        this.selected.push(event.removed.element)
        this.diff.forEach((item, index) => {
          if (item.name == event.removed.element.name) {
            this.diff.splice(index, 1)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.3;
  background: #c8ebfb;
}

.draggableItem:hover {
  cursor: move;
}

tr:hover {
  background-color: transparent !important;
}

td {
  user-select: none;
}
</style>
